import axios from "axios";
import htmx from "htmx.org";

window.htmx = htmx;

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

type OptionalEvent = { event?: string };
export type GtmDataLayerItem = OptionalEvent & {
  [eventVariable: string]: string | boolean | number;
};

type InterpolateTypes = number | string;
type InterpolateData =
  | InterpolateTypes[]
  | {
      [key: string]: InterpolateTypes;
    };

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const BrightNetwork: any;
  interface Window {
    dataLayer: GtmDataLayerItem[];
    // used for translations
    // https://docs.djangoproject.com/en/stable/topics/i18n/translation/#using-the-javascript-translation-catalog
    gettext: (str: string) => string;
    ngettext: (singular: string, plural: string, count: number) => string;
    interpolate: (
      format: string,
      data: InterpolateData,
      named: boolean,
    ) => string;
    htmx: typeof htmx;
  }
}

window.dataLayer = window.dataLayer || [];

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
function setVariableForMobile() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

function updateHeaderHeight() {
  const header = document.querySelector("header");
  if (header) {
    document.documentElement.style.setProperty(
      "--header-height",
      `${header.offsetHeight}px`,
    );
  }
}

document.addEventListener("DOMContentLoaded", () => {
  setVariableForMobile();
  updateHeaderHeight();
});

window.addEventListener("resize", updateHeaderHeight);

const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;

if (!isTouchDevice) {
  const items = document.querySelectorAll<HTMLLIElement>(
    ".bn-header .dropdown",
  );
  items.forEach((el) => {
    const link = el.querySelector("a");
    el?.addEventListener("mouseover", () => {
      el.classList.add("show");
      link?.nextElementSibling?.classList.add("show");
      link?.setAttribute("aria-expanded", "true");
    });
    el?.addEventListener("mouseout", () => {
      el.classList.remove("show");
      link?.nextElementSibling?.classList.remove("show");
      link?.setAttribute("aria-expanded", "false");
    });
  });
}

document.addEventListener(
  "invalid",
  (() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (e: any) => {
      setTimeout(() => {
        const { target } = e;
        const parentForm = target.closest("form");
        const firstInvalid = parentForm.querySelector(":invalid");
        if (target === firstInvalid) {
          target.scrollIntoView({
            behavior: "smooth",
            inline: "center",
            block: "center",
          });
        }
      }, 0);
    };
  })(),
  true,
);

window.addEventListener("resize", setVariableForMobile);
